import styled from 'styled-components';
import { colors } from 'shared/theme';

export const FooterContainer = styled.div`
  z-index: 1;
  width: calc(100vw - 10%);
  right: 0;
  left: 0;

  h3.ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #9aa6b7;
    max-width: 300px;
  }

  h3.ui.header .sub.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.basicText};
    max-width: 300px;
  }

  .eight.wide.computer.sixteen.wide.mobile.column.copyright-p {
    text-align: right;
  }

  h3.ui.header.copyright {
    max-width: 100%;
    .sub.header {
      max-width: 100%;
      text-align: left;
    }
  }

  @media (max-width: 767px) {
    h3.ui.header.copyright {
      .sub.header {
        text-align: center;
        margin: 0;
      }
    }

    .eight.wide.computer.sixteen.wide.mobile.column.copyright-p {
      text-align: center;
    }
  }
  .ui.menu {
    border: none;
    box-shadow: none;
    background: transparent;

    .item > img:not(.ui) {
      width: 200px;
    }
    .ui.basic.button {
      box-shadow: none;
      border: none;
      color: ${colors.basicText};
      font-size: 16px !important;
    }
    .sign-in-btn {
      color: ${colors.mainOrange} !important;
    }

    @media (max-width: 767px) {
      img.ui.image.logo {
        width: 133px !important;
      }
      h3.ui.header {
        width: 100vw;
      }
    }
    .ui.header > .image:not(.icon),
    .ui.header > img {
      width: 200px;
    }

    .ui.small.image {
      width: 27px !important;
    }
  }
`;
