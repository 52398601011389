import React, { useState, ReactChild, useEffect } from 'react';
import AddToHome from 'shared/AddToHome';
import { isMobileDevice, getLastVisit, saveLastVisit } from 'shared/utils';

const AppContext: any = React.createContext({});

type Props = {
  children: ReactChild;
};

const AppProvider = ({ children }: Props) => {
  const [searchedMedicationID, setSearchedMedicationID] = useState();
  const [searchedMedicationName, setSearchedMedicationName] = useState();
  const [searchedZipCode, setSearchedZipCode] = useState();
  const [showSearchDetails, setShowSearchDetails] = useState();
  const [medicationDetails, setMedicationDetails] = useState({});
  const [showAddToHome, setShowAddToHome] = useState(false);

  const addToHomeCanBeShowed =
    isMobileDevice.iOS() && !window.matchMedia('(display-mode: standalone)').matches;

  const showAddToHomeMessage = () => {
    setTimeout(() => {
      setShowAddToHome(true);
    }, 5000);
  };

  useEffect(() => {
    const checkLastVisit = () => {
      const lastVisitTimespan = getLastVisit();
      if (!lastVisitTimespan) {
        if (addToHomeCanBeShowed) {
          showAddToHomeMessage();
        }
      } else {
        const currentDate = new Date(Date.now());
        const lastVisitDate = new Date(lastVisitTimespan);
        // This allow us to show the Messages if the last visit was more than 2 days ago.
        const validDifference = currentDate.getDate() - lastVisitDate.getDate() >= 2;
        if (validDifference) {
          if (addToHomeCanBeShowed) {
            showAddToHomeMessage();
          }
        }
      }
      saveLastVisit(Date.now());
    };
    checkLastVisit();
  }, [addToHomeCanBeShowed]);

  return (
    <AppContext.Provider
      value={{
        searchedMedicationID,
        setSearchedMedicationID,
        searchedZipCode,
        setSearchedZipCode,
        showSearchDetails,
        setShowSearchDetails,
        medicationDetails,
        setMedicationDetails,
        searchedMedicationName,
        setSearchedMedicationName,
      }}
    >
      <AddToHome onClose={() => setShowAddToHome(false)} show={showAddToHome} />
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
