import React from 'react';
import {
  BlueBgContainer,
  FactsCard,
  HowItWorksContainer,
  PrescriptionBlueBg,
  Spacer,
} from './styled';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import StepOneIcon from 'assets/icons/step1.svg';
import StepTwoIcon from 'assets/icons/step2.svg';
import StepThreeIcon from 'assets/icons/step3.svg';
import ProviderPortalMock from 'assets/icons/providerPortalMock.svg';
import CheckMarkIcon from 'assets/icons/checkmark.svg';
import HappyFaceIcon from 'assets/icons/happyFace.svg';
import DocIcon from 'assets/icons/doc.svg';
import AdminIcon from 'assets/icons/admin.svg';
import DiscountIcon from 'assets/icons/discount.svg';
import PersonalizationIcon from 'assets/icons/personalization.svg';
import AnalyticsIcon from 'assets/icons/analytics.svg';
import SecurityIcon from 'assets/icons/security.svg';
import ContactForm from 'components/ContactForm';

const HowItWorks = () => {
  return (
    <HowItWorksContainer>
      <Header as='h2'>
        Our Program
        <Header.Subheader className='primary-c'>
          Our 3 Step process couldn’t be any simpler and takes less than 10 Minutes
        </Header.Subheader>
      </Header>
      <Grid doubling textAlign='center' centered>
        <Grid.Row columns={3} centered verticalAlign='middle'>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as='h3' image>
              <Image src={StepOneIcon} size='large' />
              <br />
              Build messaging campaign
              <Header.Subheader>
                Build or Select your messaging campaign (we can help you if you’d like)
              </Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as='h3' image>
              <Image src={StepTwoIcon} size='large' />
              <br />
              Upload phone numbers
              <Header.Subheader>
                Easily upload or drag and drop the numbers you want to message – don’t worry, our
                platform is secure and HIPAA compliant
              </Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as='h3' image>
              <Image src={StepThreeIcon} size='large' />
              <br />
              Send
              <Header.Subheader>
                Select the language, phone number, and frequency for sending – it’s that simple
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column mobile={16} computer={8}>
            <Image src={ProviderPortalMock} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Header as='h3' image>
              We give you the flexibility to fit your needs
              <Header.Subheader>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column width={2} verticalAlign='middle'>
                      <Image src={CheckMarkIcon} />{' '}
                    </Grid.Column>
                    <Grid.Column width={14} verticalAlign='middle'>
                      Build your own fully customizable campaign
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column width={2} verticalAlign='middle'>
                      <Image src={CheckMarkIcon} />{' '}
                    </Grid.Column>
                    <Grid.Column width={14} verticalAlign='middle'>
                      Use one of our already proven campaigns – even use our proven content and
                      recommended frequency and delivery schedules
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column width={2} verticalAlign='middle'>
                      <Image src={CheckMarkIcon} />{' '}
                    </Grid.Column>
                    <Grid.Column width={14} verticalAlign='middle'>
                      Let our team of industry experts work personally with you to build your own
                      campaign to fit your needs and achieve your goals
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Spacer />
      <BlueBgContainer>
        <Grid textAlign='center'>
          <Grid.Row columns={1}>
            <Header as='h1'>Do you have patients that can’t accept texts?</Header>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Header as='h2'>
              No problem – our solution transcribes your message to audio. Why Text? Most effective
              and cost efficient communication method.
            </Header>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Header as='h3'>
              “98% of text messages are read by the end of the day.”
              <Header.Subheader>TextLocal, 2017</Header.Subheader>
            </Header>
          </Grid.Row>
        </Grid>
      </BlueBgContainer>
      <Spacer />
      <Header as='h2'>
        Messaging Campaigns
        <Header.Subheader className='primary-s'>
          Our campaigns are customized and provide an extensive list of benefits to your facility,
          staff, and patient population
        </Header.Subheader>
      </Header>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column mobile={16} computer={8}>
            <FactsCard>
              <Image src={HappyFaceIcon} />
              <Header as='h3'>
                Positive Press, Patient Savings
                <Header.Subheader>
                  <ul>
                    <li>White-labeled discount card </li>
                    <li>Patient assistance programs </li>
                    <li>Copay assistance </li>
                    <li>Patient satisfaction survey</li>
                  </ul>
                </Header.Subheader>
              </Header>
            </FactsCard>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <FactsCard>
              <Image src={DocIcon} />
              <Header as='h3'>
                Health Outcomes
                <Header.Subheader>
                  <ul>
                    <li>
                      Text a patient the name and address of their pharmacy once a prescription is
                      written{' '}
                    </li>
                    <li>Verify a prescription was picked up via personalized patient texts</li>
                    <li>Find out why a prescription was abandoned (cost, side…)</li>
                    <li>Patient satisfaction survey</li>
                    <li>Refill reminders</li>
                    <li>Disease state education</li>
                  </ul>
                </Header.Subheader>
              </Header>
            </FactsCard>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column mobile={16} computer={8}>
            <FactsCard>
              <Image src={AdminIcon} />
              <Header as='h3'>
                Administrative
                <Header.Subheader>
                  <ul>
                    <li>Appointment reminders</li>
                    <li>Bill payments</li>
                    <li>Lab results and testing initiatives</li>
                    <li>Open enrollment</li>
                  </ul>
                </Header.Subheader>
              </Header>
            </FactsCard>
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <FactsCard>
              <Image src={DiscountIcon} />
              <Header as='h3'>
                340B
                <Header.Subheader>
                  <ul>
                    <li>340B Discount cards</li>
                    <li>Contract pharmacy conversion </li>
                    <li>Freedom of choice</li>
                  </ul>
                </Header.Subheader>
              </Header>
            </FactsCard>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Spacer />
      <PrescriptionBlueBg>
        <Grid textAlign='center'>
          <Grid.Row columns={1}>
            <Header as='h1'>Prescription Savings Card</Header>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Header as='h2'>
              White labeled discount card, branded with{' '}
              <strong>your organization’s name and logo. Savings up to 80%</strong> and accepted at
              over <strong>50,000 pharmacies nationwide.</strong>
              <br />
              <br /> Simply text the cards to your patients to be presented at the pharmacy of
              choice and enjoy great savings.
            </Header>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Button as='a' href='https://provider.elevatedhealthnetwork.com' size='medium'>
              Visit Page
            </Button>
          </Grid.Row>
        </Grid>
      </PrescriptionBlueBg>
      <Spacer />
      <Header as='h2'>Benefits our customers love</Header>
      <Grid doubling textAlign='center' centered>
        <Grid.Row columns={3} centered verticalAlign='middle'>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as='h3' image>
              <Image src={PersonalizationIcon} size='large' />
              <br />
              Personalization
              <Header.Subheader>
                Select time and frequency of messaging for individual or groups of patients
              </Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as='h3' image>
              <Image src={AnalyticsIcon} size='large' />
              <br />
              Analytics
              <Header.Subheader>
                How did time, frequency, day impact open rate and response rate based on message,
                demographics etc
              </Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as='h3' image>
              <Image src={SecurityIcon} size='large' />
              <br />
              Security
              <Header.Subheader>
                HIPAA compliant messaging and no messages are stored
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Spacer />
      <BlueBgContainer>
        <Grid textAlign='center'>
          <Grid.Row columns={1}>
            <Header as='h2'>
              &quot;As <strong>COVID-19 </strong>hit and it was a rush to get important messages to
              our patients,
              <strong> Elevated Health Network was an incredible partner.</strong> They work quickly
              and efficiently and were able to adjust on the fly when we had to change course with
              quickly changing information. We were able to contact thousands of patients in two
              different languages within days.{' '}
              <strong>
                They are extremely responsive and help guide you with their expertise.
              </strong>{' '}
              We will definitely continue to work with them in the future and highly recommend
              others to do so as well.&quot;
            </Header>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Header as='h3'>
              <Header.Subheader>
                Director of Communications Sixteenth Street Community Health Centers Milwaukee
                Wisconsin
              </Header.Subheader>
            </Header>
          </Grid.Row>
        </Grid>
      </BlueBgContainer>
      <Spacer />
      <ContactForm />
    </HowItWorksContainer>
  );
};

export default HowItWorks;
