import React, { useContext, useRef } from 'react';
import { HeaderContainer } from './styled';
import { Menu, Image, Header, Grid, Button } from 'semantic-ui-react';
import EHNIsoTrans from 'assets/logos/ehnisoTrans.png';
import HeaderArt from 'assets/icons/headerArt.svg';
import { AppContext } from 'containers/App/context';

const scrollToRef = (ref: any) =>
  window.scrollBy({
    top: ref.current.offsetTop + 40,
    left: 0,
    behavior: 'smooth',
  });

const CHeader = () => {
  const { setShowSearchDetails, setMedicationDetails } = useContext(AppContext);
  const myRef = useRef(null);

  const onBackToHome = () => {
    setMedicationDetails({});
    setShowSearchDetails(false);
  };

  return (
    <HeaderContainer>
      <Menu borderless>
        <Menu.Item onClick={onBackToHome} position='left'>
          <Image src={EHNIsoTrans} />
        </Menu.Item>
        <Menu.Item position='right'>
          <Grid className='btn-actions'>
            <Grid.Row columns={2}>
              <Grid.Column computer={8} mobile={16}>
                <Button
                  as='a'
                  href='https://provider.elevatedhealthnetwork.com/login'
                  compact
                  basic
                  fluid
                  color='orange'
                >
                  Log in
                </Button>
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Button
                  as='a'
                  href='https://provider.elevatedhealthnetwork.com/sign-up'
                  color='orange'
                  fluid
                >
                  Sign Up
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Menu.Item>
      </Menu>

      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={10}>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Header as='h1'>
                    Improve the communication <br /> process between your facility <br />
                    and patients
                    <Header.Subheader>
                      Our messaging system effectively increases revenues, patient compliance and
                      participation, efficiencies, and overall patient wellness.
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column mobile={16} computer={5}>
                  <Button
                    as='a'
                    href='https://provider.elevatedhealthnetwork.com/sign-up'
                    target='_blank'
                    fluid
                    color='orange'
                  >
                    Get started
                  </Button>
                </Grid.Column>
                <Grid.Column mobile={16} computer={5}>
                  <Button onClick={() => scrollToRef(myRef)} basic color='orange' fluid>
                    Read more
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={6}>
            <Image src={HeaderArt} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div ref={myRef} />
    </HeaderContainer>
  );
};
export default CHeader;
