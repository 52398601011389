import React from 'react';
import { FooterContainer } from './styled';
import { Menu, Header, Image, Grid } from 'semantic-ui-react';
import EHNIsoTrans from 'assets/logos/ehnisoTrans.png';
// import MailIcon from 'assets/icons/mail.svg';
// import CallIcon from 'assets/icons/phone.svg';

const Footer = () => {
  return (
    <FooterContainer>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Menu stackable borderless position='left'>
              <Menu.Item>
                <Grid columns={1} textAlign='left'>
                  <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                      <Header as='h3' image>
                        <Image className='logo' src={EHNIsoTrans} />
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Menu.Item>

              {/* <Grid textAlign='left'>
                <Grid.Row columns={3} verticalAlign='middle'>
                  <Grid.Column></Grid.Column>
                  <Grid.Column>
                    <Header as='h3' image>
                      <Grid textAlign='left'>
                        <Grid.Row columns={2}>
                          <Grid.Column mobile={4} computer={4}>
                            <Image src={CallIcon} className='small' />
                          </Grid.Column>
                          <Grid.Column mobile={8} computer={12}>
                            <Header.Subheader>855-798-2538</Header.Subheader>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Header>
                  </Grid.Column>

                  <Grid.Column>
                    <Header as='h3' image>
                      <Grid textAlign='left'>
                        <Grid.Row columns={2}>
                          <Grid.Column verticalAlign='middle' mobile={4} computer={4}>
                            <Image src={MailIcon} className='small' />
                          </Grid.Column>
                          <Grid.Column verticalAlign='middle' mobile={8} computer={12}>
                            <Header.Subheader>info@elevatedhealthnetwork.com</Header.Subheader>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid> */}
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} verticalAlign='middle'>
          <Grid.Column>
            <Header as='h3' className='copyright'>
              <Grid textAlign='center'>
                <Grid.Row columns={1} verticalAlign='middle'>
                  <Grid.Column className='copyright-p'>
                    © 2020 Elevated Health Network | All Rights Reserved
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FooterContainer>
  );
};
export default Footer;
