import styled from 'styled-components';
import { colors } from 'shared/theme';

export const HowItWorksContainer = styled.div`
  position: relative;
  width: 100%;
  .sub.header.primary-c {
    max-width: 400px;
    margin: 20px auto !important;
    align-self: center;
  }
  .sub.header.primary-s {
    max-width: 520px;
    margin: 20px auto !important;
    align-self: center;
  }
  h2.ui.header {
    text-align: center;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: -0.01em;
    color: ${colors.basicText};
  }
  h3.ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: ${colors.basicText};
    margin-bottom: 15px;
  }
  h3.ui.header .sub.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: 15px;
  }
  .ui.grid {
    /* margin-bottom: 180px; */
  }
  .ui.header > .image:not(.icon),
  .ui.header > img {
    margin-bottom: 30px;
    width: 64px;
  }

  img.ui.image.partners-mobile {
    display: none;
  }

  @media (max-width: 767px) {
    img.ui.image.partners {
      display: none;
    }
    img.ui.image.partners-mobile {
      display: block;
      width: 100%;
      margin-top: 50px;
    }
  }
`;

export const BlueBgContainer = styled.div`
  background: linear-gradient(0deg, #d7e9ff, #d7e9ff), #ffffff;
  border-radius: 10px;
  padding: 50px;
  .ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.01em;
  }

  h1.ui.header {
    color: #1c7cec;
    font-size: 32px;
    line-height: 37px;
    max-width: 444px;
  }
  h2.ui.header {
    font-size: 16px;
    line-height: 22px;
    color: #1a3a61;
    opacity: 0.8;
    max-width: 580px;
  }

  h3.ui.header {
    font-size: 16px;
    line-height: 22px;
    color: #1a3a61;
  }
  .sub.header {
    max-width: 490px;
  }
  @media (max-width: 767px) {
    padding: 30px;
    h1.ui.header {
      font-size: 25px;
      line-height: 25px;
    }
    h2.ui.header {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const FactsCard = styled.div`
  background: #f6f7f9;
  border-radius: 10px;
  padding: 50px 74px;
  min-height: 420px;
  h3.ui.header {
    font-weight: bold;
  }
  @media (max-width: 767px) {
    padding: 15px;
    min-height: auto;
    margin: 10px 0;
  }
`;

export const PrescriptionBlueBg = styled.div`
  background: linear-gradient(277.84deg, rgba(28, 124, 236, 0.3) 0%, rgba(28, 124, 236, 0) 100%),
    linear-gradient(0deg, #1a3a61, #1a3a61), #ffffff;
  border-radius: 10px;
  padding: 50px;
  .ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
  }
  h1.ui.header {
    font-size: 32px;
    line-height: 37px;
    max-width: 444px;
  }
  h2.ui.header {
    font-size: 16px;
    line-height: 22px;
    opacity: 0.8;
    color: white;
    max-width: 580px;
  }
  @media (max-width: 767px) {
    h1.ui.header {
      font-size: 25px;
      line-height: 25px;
      max-width: auto;
    }
    h2.ui.header {
      font-size: 14px;
      line-height: 16px;
      max-width: auto;
    }
  }
`;

export const Spacer = styled.div`
  width: 100%;
  height: 100px;
  @media (max-width: 767px) {
    height: 30px;
  }
`;
