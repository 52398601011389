import styled from 'styled-components';

export const HomeContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  padding-top: 100px;
  @media (max-width: 767px) {
    padding-top: 50px;
  }
`;

export const LeftGradientReq = styled.div`
  position: absolute;
  width: 100%;
  height: 654px;
  left: 0px;
  top: 0px;

  @media (max-width: 767px) {
    width: 100%;
    height: 480px;
  }

  background: linear-gradient(
      111.82deg,
      rgba(255, 255, 255, 0) 9.54%,
      rgba(255, 255, 255, 0.8) 98.71%
    ),
    #d7e9ff;
`;

export const RightGradientRq = styled.div`
  border-bottom-right-radius: 150px;
  position: absolute;
  width: 650px;
  height: 650px;
  right: -50px;
  top: 280px;

  background: linear-gradient(
    165.01deg,
    rgba(255, 255, 255, 0) 16.45%,
    rgba(255, 96, 0, 0.12) 89.44%
  );
  transform: rotate(-180deg);

  @media (max-width: 767px) {
    width: 200px;
    height: 332px;
    right: 0;
  }
`;
